import { Address } from '@medusajs/medusa';
import { Radio, Tag } from 'antd';
import React from 'react';

import { formatAddressLine } from '../../helpers/address.helper';
import useCheckout from '../../hooks/useCheckout';
import useCountry from '../../hooks/useCountry';

const DeliveryAddressOptionList = () => {
  const { addresses } = useCheckout();

  return (
    <ul className="flex flex-col gap-y-3">
      {addresses.map((address) => (
        <DeliveryAddressOption address={address} key={address.id} />
      ))}
    </ul>
  );
};

const DeliveryAddressOption = ({ address }: { address: Address }) => {
  const { selectedShippingAddress, setSelectedShippingAddress } = useCheckout();
  const { getOption } = useCountry();

  const addressLine = formatAddressLine(address);

  const country = address.country_code
    ? getOption(address.country_code)?.label
    : '';

  return (
    <li key={address.id}>
      <Radio
        className="!m-0 !flex w-full !items-center rounded-md border border-light-border bg-neutral-1 !p-4 shadow-small"
        onChange={() => setSelectedShippingAddress(address)}
        checked={address.id === selectedShippingAddress?.id}
      >
        <div className="ml-2 flex flex-col gap-y-1 text-sm text-light-title">
          <div className="font-semibold">
            <span className="mr-2">
              {address.first_name} {address.last_name}
            </span>
            {address.metadata?.isDefault ? (
              <Tag color="blue">Default</Tag>
            ) : null}
          </div>
          <div>{address.company}</div>
          <div>
            {addressLine} <br />
            {address.province}, {address.postal_code}, {country}
          </div>
          <div>{address.phone}</div>
        </div>
      </Radio>
    </li>
  );
};

export default DeliveryAddressOptionList;
